.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.drawer-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}

.drawer-app-content {
  flex: auto;
  overflow: auto;
}

.top-app-bar-fix-adjust {
  display: flex;
  width: 100%;
}

.mdc-drawer--dismissible {
  top: auto;
}

.menu:hover {
  background-color: #ebebeb;
}

.menu {
  color: black;
}

/* .mdc-top-app-bar	{
  background-color: #282c34 !important;
} */

.mdc-top-app-bar__section {
  background-color: #0088d1;
}

.MuiButton-containedPrimary {
  background-color: #0288d1 !important;
  
}

.MuiButton-containedPrimary:disabled {
  background-color: grey !important;
  color: white !important
  
}