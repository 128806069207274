.RegisterWrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
}

.Logo {
    background-color: rgb(241, 245, 240);
    min-height: 100%;
    flex: 1;
    padding-block: 10%;
    padding-inline: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Form {
    background-color: rgba(19, 34, 50, 1);
    min-height: 100%;
    flex: 1;
    padding-block: 10%;
    padding-inline: 5%;
}